
import React from 'react';
import valid from "../images/valid.png"
export default (props) => {
 const { data, period, setShowBuy } = props
  return (
    <div className="d-flex flex-row justify-content-evenly flex-wrap">
        {data.length && data.map((val) => {
            return (
                <div key={val.id} style={{ border: "1px solid #ffa500", borderRadius: "5px", color: "white", position: "relative" }} className='d-flex flex-column p-4 bg-primary mb-2'>
                    {/* {["Silver Pack", "Diamond Pack"].includes(val.name) && (<div style={{ position: "absolute", top: "0", left: "50%", width: 'fit-content', height: 'fit-content', padding: ".6em", backgroundColor: "orange"}}>Popular</div>)} */}
                <center><span style={{ fontSize: "3rem" }}>{val.name}</span></center>
                <i style={{ fontSize: "3rem" }}><center>€{val.price} <span style={{ fontSize: "1.8rem" }}> / {period}</span></center></i>
                {val.advantages.length && val.advantages.map((val, i) => {
                    return (<span key={i} style={{ fontSize: "1.8rem", marginTop: "10%" }}><img width={24} height={24} src={valid} /> {val}</span>)
                })}
                <div id="button-buy-now" onClick={() => setShowBuy({...val, period})} className='p-2 mt-4' style={{ border: "1px solid #ffa500", borderRadius: "5px", cursor: "pointer" }}><center><span style={{ fontSize: "1.8rem" }}>Buy now</span></center></div> 
                </div>
            )
        })}
    </div>
  );
};
