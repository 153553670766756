import React from "react";
import useTitle from "../../hooks/useTitle";
import { Container } from "@themesberg/react-bootstrap";

const termsAndConditions = [
    {
        title: "TERMS AND CONDITIONS",
        value: "By visiting or using the website utsniper.com, you agree to these terms and conditions in full. We hold the right to modify these terms at any time without notice. It is your responsibility to be aware of these terms and review them regularly."
    },
    {
        title: "GOODS SOLD",
        value: "utsniper.com only sells digital goods. Therefore, no physical goods will be delivered to your address. After your payment has been processed, you will receive your package instantly."
    },
    {
        title: "Premium",
        value: 'For the premium part you will be connected to our "premium server" which means you will receive filters to be used directly inside our extension.'
    },
    {
        title: "Uninstalling Process",
        value: 'When you gonna uninstall the UTSniper chrome extension, we gonna redirect you into a form where you can explain why you uninstalled our product, this is totally anonym no infos are collected except your "feedback".'
    },
    {
        title: "Copyright",
        value: "All content on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, and data compilations is the property of UTSniper or its content suppliers and is protected by domestic and international copyright laws. The compilation of all content on this site is the exclusive property of UTSniper and is protected by domestic and international copyright laws. All software used on this site is the property of UTSniper or its software suppliers and is protected by domestic and international copyright laws."
    },
    {
        title: "User Guidelines",
        value: "You can use as much EA account as you want, according to your package. You must always provide correct information when required. Do not attempt to hack the website and do not use possible script vulnerabilities. Violators will be immediately banned. You agree to any possible sanctions from EA that may arise as a result of utilizing our services. Our program is as safe as possible, but there are always risks."
    },
    {
        title: "Disputes",
        value: "If you have any concerns or disputes about the service, you agree to first try to resolve the dispute by informally contacting us."
    }
];


export default () => {
  useTitle('Terms of service')
  return (
    <main className="d-flex flex-column align-items-center my-4">
    <section className="vh-lg-100 d-flex align-items-center my-4">
      <Container style={{ border: '2px solid black', borderRadius: '5px', padding: '2em' }}>
          <ol>
            {termsAndConditions.map((values, i) => {
                return (
                <li className="flex flex-col text-white" key={i}>
                    <p className="text-start">{values.title}</p>
                    <p className="text-start">{values.value}</p>
                </li>)
            })}
          </ol>
      </Container>
    </section>
  </main>
  );
};
