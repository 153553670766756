import React, { useEffect, useState } from 'react';
import axios from "../../api/index"
import { useParams } from 'react-router-dom';
import Preloader from '../../components/Preloader';
import { Container } from '@themesberg/react-bootstrap';
import useTitle from '../../hooks/useTitle';

export default (props) => {
  const [loaded, setLoaded] = useState(false)
  const [status, setStatus] = useState({ type: null, message: null })
  const params = useParams()
  useTitle('Confirm Account')
  useEffect(() => {
    let isMounted 
    if(!isMounted) {
        const verifyCode = async () => {
            try {
                const resp = await axios.get(`/confirm-account/${params.confirmCode}`)
                if(resp.status === 201) {
                    setStatus({ type: "success", message: "Your account got verified with success ! Redirecting you..." })
                    setTimeout(() => window.location.href = "/#/sign-in", 5250)
                } else {
                    setStatus({ type: "danger", message: "Something went wrong, please verify your verification code." })
                }
               } catch {
                    setStatus({ type: "danger", message: "Something went wrong, please verify your verification code." })
               } finally {
                   setLoaded(true)
               }
        }
        verifyCode()
        
    } 
    return () => isMounted = true
  }, [])

  if(!loaded && !status.type) {
    return <Preloader show={true} />
  } else {
      return (
        <main className="d-flex flex-column align-items-center my-4">
        <section className="vh-lg-100 d-flex align-items-center my-4">
          <Container>
            {status.type === "success" ?
                (<div class="alert alert-success mt-2" role="alert">
                {status.message}
                </div>)
            : 
                (<div class="alert alert-danger mt-2" role="alert">
                {status.message}
                </div>)
            }
          </Container>
        </section>
      </main>
      );
  }
};
