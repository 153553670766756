
import React, { useRef, useState } from "react";
import { Button, Container, Form, InputGroup } from '@themesberg/react-bootstrap';
import useTitle from "../../hooks/useTitle";
import ReCAPTCHA from "react-google-recaptcha";

export default () => {
  useTitle('Successfully uninstalled')
  const [values, setValues] = useState({ feedback: "" })
  const [infos, setInfos] = useState({ type: "", message: "" })
  const [loading, setLoading] = useState("")
  const recaptchaRef = useRef(null)
  const submit = async () => {
    try {
        if(values.feedback.length > 0 && values.feedback.length <= 255) {
            setLoading(true)
            const captchaToken = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            await fetch(`${process.env.REACT_APP_BASE_URL}submit-feedback`, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ feedback: values.feedback, recaptcha: captchaToken })
              })
            
            setInfos({ type: "success", message: "Feedback submitted, thanks for helping us."})
        } else {
            setInfos({ type: "error", message: "Your feedback can be maximum 255 characters and cannot be empty."})
        }
    } catch (e) {
        setInfos({ type: "error", message: "An error occured."})
    } finally {
        setLoading(false)
    }
    
  }
  return (
    <main className="d-flex flex-column align-items-center my-4">
        {infos.type !== "success" ? (
            <>
            <h1 className="text-white mt-2">Uninstalled with success !</h1>
            <section className="vh-lg-100 mt-4">
                <h4 className="text-white text-center mt-6">We are sorry to hear that UTSniper failed to meet your expectations, it would help us a lot if you give us the reasons for uninstalling it</h4>
                <Container className="mt-6">
                    <Form>
                    <Form.Group id="feedback" className="mb-4">
                            <InputGroup onChange={(e) => setValues({ ...values, feedback: e.target.value })}>
                            <Form.Control as="textarea" autoFocus required maxLength={255} type="feedback" placeholder="Hello..." />
                            </InputGroup>
                    </Form.Group>
                    <Button onClick={() => !loading&&submit()} variant="success" className="w-100">
                            {!loading?"Submit feedback":"Loading..."}
                    </Button>
                    {infos.type === "error" && (
                        <div class="alert alert-danger mt-2" role="alert">
                         {infos.message}
                        </div>
                    )}
                    </Form>
                </Container>
                <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        size="invisible"
                />
            </section>
            </>
        ): (
            <div class="alert alert-success mt-2" role="alert">
                         {infos.message}
            </div>
        )}
    </main>
  );
};
