
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import { pageTraffic, pageRanking } from "../data/tables";
import transactions from "../data/transactions";
import commands from "../data/commands";
import Confirmed from "../pages/images/status-confirmed.svg"
import Canceled from "../pages/images/status-canceled.svg"
import Pending from "../pages/images/status-pending.svg"
import Progress from "../pages/images/status-progress.svg"
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Modal } from "bootstrap";
const pageVisits =[
  {
      "id": 1,
      "amount": 255,
      "transactionId": "1826386938639863",
      "status": "Confirmed",
      "date": "18/11/2023",
      "packages": "Basic"
  },
  {
      "id": 2,
      "amount": 139,
      "transactionId": "1826386938639863",
      "status": "In progress",
      "date": "18/11/2023",
      "packages": "Premium"
  },
  {
      "id": 3,
      "amount": 124,
      "transactionId": "1826386938639863",
      "status": "Canceled",
      "date": "18/11/2023",
      "packages": "Premium"
  },
  {
      "id": 4,
      "amount": 55,
      "transactionId": "1826386938639863",
      "status": "Waiting for payment",
      "date": "18/11/2023",
      "packages": "Silver"
  },
  {
      "id": 5,
      "amount": 3,
      "transactionId": "1826386938639863",
      "status": "Canceled",
      "date": "18/11/2023",
      "packages": "Basic"
  },
  {
      "id": 6,
      "amount": 96,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 7,
      "amount": 145,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 8,
      "amount": 484,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 9,
      "amount": 46,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 10,
      "amount": 32,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 11,
      "amount": 1,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 12,
      "amount": 401,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 13,
      "amount": 300,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 14,
      "amount": 457,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 15,
      "amount": 165,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 16,
      "amount": 122,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 17,
      "amount": 267,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 18,
      "amount": 92,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 19,
      "amount": 416,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 20,
      "amount": 152,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 21,
      "amount": 7,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 22,
      "amount": 290,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 23,
      "amount": 478,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 24,
      "amount": 135,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  },
  {
      "id": 25,
      "amount": 224,
      "transactionId": "1826386938639863",
      "packages": "Premium",
      "status": "Pending",
      "date": "18/11/2023"
  }
];
const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const PageVisitsTable = ({showDetails, setShowDetails}) => {

  const getOrders = async () => {
    await axiosPrivate.get("get-orders").then((res) => {
      if(res.status === 201) {
        return setOrdersReceived(res.data.orders)
      }
    })
  }
  
  const axiosPrivate = useAxiosPrivate()
  const [ ordersReceived, setOrdersReceived ] = useState([])
  useEffect(() => {
    let isMounted = true
    if(isMounted) {
      getOrders()
    }
    return () => isMounted = false
  }, [])
  const TableRow = (props) => {
    const { transaction_id, price, status, created_at, txn_id, network, name, advantages } = props;
    const svgStatus = status === "Confirmed" ? Confirmed : status === "Canceled" ? Canceled : status === "Waiting for payment" ? Pending : Progress;
      return (
        <tr style={{ position: "relative" }}>
          <th scope="row">{transaction_id}</th>
          <td scope="row">{name}</td>
          <td scope="row">${price}</td>
          <td scope="row"><img height={20} width={20} src={svgStatus} /> {status}</td>
          <td scope="row">{new Date(created_at).toLocaleDateString()}</td>
          <td scope="row" >
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setShowDetails({ transaction_id, price, status, created_at, txn_id, network, name, advantages })}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        </tr>
      );
    };

    // if(!isLoading && orders.length) {
      return (
        <Card border="light" className="shadow-sm">
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5>Total transactions</h5>
              </Col>
            </Row>
          </Card.Header>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col">Transaction ID</th>
                <th scope="col">Package</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {ordersReceived?.length ? 
              ordersReceived.map(pv => <TableRow key={`page-visit-${pv.id}`} {...pv} />) :
              (<center><span style={{ fontSize: "3rem" , margin: "auto" }}>No transactions found</span></center>)
              }
            </tbody>
          </Table>
        </Card>
      );
    // }

};

export const PageTrafficTable = () => {
  const TableRow = (props) => {
    const { id, source, sourceIcon, sourceIconColor, sourceType, category, rank, trafficShare, change } = props;

    return (
      <tr>
        <td>
          <Card.Link href="#" className="text-primary fw-bold">{id}</Card.Link>
        </td>
        <td className="fw-bold">
          <FontAwesomeIcon icon={sourceIcon} className={`icon icon-xs text-${sourceIconColor} w-30`} />
          {source}
        </td>
        <td>{sourceType}</td>
        <td>{category ? category : "--"}</td>
        <td>{rank ? rank : "--"}</td>
        <td>
          <Row className="d-flex align-items-center">
            <Col xs={12} xl={2} className="px-0">
              <small className="fw-bold">{trafficShare}%</small>
            </Col>
            <Col xs={12} xl={10} className="px-0 px-xl-1">
              <ProgressBar variant="primary" className="progress-lg mb-0" now={trafficShare} min={0} max={100} />
            </Col>
          </Row>
        </td>
        <td>
          <ValueChange value={change} suffix="%" />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Traffic Source</th>
              <th className="border-0">Source Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Global Rank</th>
              <th className="border-0">Traffic Share</th>
              <th className="border-0">Change</th>
            </tr>
          </thead>
          <tbody>
            {pageTraffic.map(pt => <TableRow key={`page-traffic-${pt.id}`} {...pt} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const RankingTable = () => {
  const TableRow = (props) => {
    const { country, countryImage, overallRank, overallRankChange, travelRank, travelRankChange, widgetsRank, widgetsRankChange } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <Image src={countryImage} className="image-small rounded-circle me-2" />
            <div><span className="h6">{country}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {overallRank ? overallRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={overallRankChange} />
        </td>
        <td className="fw-bold border-0">
          {travelRank ? travelRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={travelRankChange} />
        </td>
        <td className="fw-bold border-0">
          {widgetsRank ? widgetsRank : "-"}
        </td>
        <td className="border-0">
          <ValueChange value={widgetsRankChange} />
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Country</th>
              <th className="border-0">All</th>
              <th className="border-0">All Change</th>
              <th className="border-0">Travel & Local</th>
              <th className="border-0">Travel & Local Change</th>
              <th className="border-0">Widgets</th>
              <th className="border-0">Widgets Change</th>
            </tr>
          </thead>
          <tbody>
            {pageRanking.map(r => <TableRow key={`ranking-${r.id}`} {...r} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export const TransactionsTable = ({showDetails, setShowDetails}) => {

    const getOrders = async () => {
      await axiosPrivate.get("get-orders").then((res) => {
        if(res.status === 201) {
          return setOrdersReceived(res.data.orders)
        }
      })
    }
    
    const axiosPrivate = useAxiosPrivate()
    const [ ordersReceived, setOrdersReceived ] = useState([])
    useEffect(() => {
      let isMounted = true
      if(isMounted) {
        getOrders()
      }
      return () => isMounted = false
    }, [])
  const TableRow = (props) => {
    const { transaction_id, price, status, created_at, name, network,  advantages, payment_link, payment_history } = props;
    const svgStatus = status === "Confirmed" ? Confirmed : status === "Canceled" ? Canceled : status === "Waiting for payment" ? Pending : Progress;

    return (
      <tr>
        <td>
          <b>{transaction_id}</b>
        </td>
        <td>
          <span className="fw-normal">
            {name}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            ${price}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            <img height={20} width={20} src={svgStatus} /> {status}
          </span>
        </td>
        <td>
          <span className="fw-normal">
          {new Date(created_at).toLocaleDateString()} at {new Date(created_at).toLocaleTimeString()}
          </span>
        </td>
        <td>
        <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setShowDetails({ transaction_id, price, status, created_at, network, name, advantages, payment_link, payment_history })}>
                  <FontAwesomeIcon icon={faEye} className="me-2" /> View Details
                </Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Transaction ID</th>
              <th className="border-bottom">Package</th>
              <th className="border-bottom">Amount</th>
              <th className="border-bottom">Status</th>
              <th className="border-bottom">Date</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!ordersReceived.length ? ordersReceived.map(t => <TableRow key={`transaction-${t.transaction_id}`} {...t} />) : <tr><td></td><td></td><td><b style={{ fontSize: "1.2em" }}>No orders found.</b></td><td></td><td></td><td></td></tr>}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export const CommandsTable = () => {
  const TableRow = (props) => {
    const { name, usage = [], description, link } = props;

    return (
      <tr>
        <td className="border-0" style={{ width: '5%' }}>
          <code>{name}</code>
        </td>
        <td className="fw-bold border-0" style={{ width: '5%' }}>
          <ul className="ps-0">
            {usage.map(u => (
              <ol key={u} className="ps-0">
                <code>{u}</code>
              </ol>
            ))}
          </ul>
        </td>
        <td className="border-0" style={{ width: '50%' }}>
          <pre className="m-0 p-0">{description}</pre>
        </td>
        <td className="border-0" style={{ width: '40%' }}>
          <pre><Card.Link href={link} target="_blank">Read More <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" /></Card.Link></pre>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="p-0">
        <Table responsive className="table-centered rounded" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          <thead className="thead-light">
            <tr>
              <th className="border-0" style={{ width: '5%' }}>Name</th>
              <th className="border-0" style={{ width: '5%' }}>Usage</th>
              <th className="border-0" style={{ width: '50%' }}>Description</th>
              <th className="border-0" style={{ width: '40%' }}>Extra</th>
            </tr>
          </thead>
          <tbody>
            {commands.map(c => <TableRow key={`command-${c.id}`} {...c} />)}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};