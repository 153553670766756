
import React from "react";
import useTitle from "../../hooks/useTitle";
import Tabs from "../components/Tabs"

export default () => {
  useTitle("Packages")
  return (
    <Tabs />
  );
};