
import React from "react";
import { Card, Container } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import useTitle from "../../hooks/useTitle";

export default () => {

  useTitle('Captcha Triggered')

  return (
    <main>
      <section className="vh-lg-100 d-flex align-items-center my-4">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <p style={{ border: '2px solid black', borderRadius: '5px', padding: '2em' }}>The captcha got triggered, you got redirected here by safety, you can go back to the EA Wep App and resolve the puzzle.</p>
            </Card.Link>
          </p>
        </Container>
      </section>
    </main>
  );
};
