
import React, { useState } from "react";
import { Col, Row, Card, Form, Button, Alert } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { jwtDecode } from "jwt-decode";
import useAuth from "../hooks/useAuth";

export const GeneralInfoForm = () => {
  const [ errorValue, setErrorValue ] = useState(false)
  const [ successValue, setSuccessValue ] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const [ values, setValues ] = useState({ email: "", password: "", passwordconfirm: "" })
  const [ placeholderValues, setPlaceholderValues ] = useState({ email: null })
  const { auth } = useAuth()
  const decoded = jwtDecode(auth.token);
  const onClose = () => {
    setErrorValue(false)
    setSuccessValue(false)
  };
  const updateAccount = async () => {
    onClose()
    if(!values.email.length && !values.password.length && !values.passwordconfirm.length) return
    if(values.email.length && /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(values.email) && !values.password.length) {
      if(decoded.email === values.email && !placeholderValues.email || placeholderValues.email === values.email) return setErrorValue("You attempted to configure an email identical to the one you are currently using.")
      return await axiosPrivate.patch("update-email", { email: values.email }).then((res) => {
        if(res.status === 201) {
          setPlaceholderValues((prev) => ({ ...prev, email: values.email }))
          setSuccessValue("Email updated with success")
        } else {
          setErrorValue(res.data?.error??res.data?.message)
        }
      })
    } 
    if(!values.email.length && values.password.length >= 6 && values.password === values.passwordconfirm) {
      return await axiosPrivate.patch("update-password", { password: values.password, passwordconfirm: values.passwordconfirm }).then((res) => {
        if(res.status === 201) {
          setSuccessValue("Password updated with success")
        } else {
          setErrorValue(res.data?.error??res.data?.message)
        }
      })
    }
    if(values.email.length && !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
      // console.log("Verify the email you set")
      return setErrorValue("Verify the email you set")
    }
    if(!values.email.length && (values.password.length < 6 || values.password !== values.passwordconfirm)) {
     return setErrorValue("Verify the password and the confirm password you specified, and the length need to be at least of 6.")
    }
    if(values.password.length > 5 && values.password === values.passwordconfirm && /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(values.email)){
      return await axiosPrivate.patch("update-user", { ...values }).then((res) => {
      if(res.status === 201) {
        setSuccessValue("Informations updated with success.")
      } else {
        setErrorValue(res.data?.error??res.data?.message)
      }
    })
    }

  }
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">General information</h5>
        <Form onSubmit={() => updateAccount()}>
        <React.Fragment>
                <Alert
                  variant="danger"
                  show={errorValue}
                  onClose={() => onClose()}>

                  <div className="d-flex justify-content-between">
                    <div>
                      <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                      <strong>{errorValue}</strong>.
                    </div>
                    <Button variant="close" size="xs" onClick={() => onClose()} />
                  </div>
                </Alert>
              <Alert
              variant="success"
              show={successValue}
              onClose={() => onClose()}>

              <div className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                  <strong>{successValue}</strong>
                </div>
                <Button variant="close" size="xs" onClick={() => onClose()} />
              </div>
              </Alert>
              </React.Fragment>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Username</Form.Label>
                <Form.Control required type="text" placeholder={decoded.username} disabled />
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col md={12} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Email</Form.Label>
                <Form.Control onChange={(e) => setValues({ ...values, email: e.target.value })} type="text" placeholder={placeholderValues.email ? placeholderValues.email : decoded.email} />
              </Form.Group>
          </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={(e) => setValues({ ...values, password: e.target.value })} type="text" placeholder="************" />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Confirm password</Form.Label>
                <Form.Control onChange={(e) => setValues({ ...values, passwordconfirm: e.target.value })} type="text" placeholder="************" />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3 w-100">
            <Button onClick={() => updateAccount()}  id="button-buy-now" className="w-100" variant="primary">Save Changes</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
