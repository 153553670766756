
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Col, Row, Card, Button, Tooltip, OverlayTrigger } from '@themesberg/react-bootstrap';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import Code from "./Code";

import themeStyle from "../assets/syntax-themes/ghcolors.json";

export default (props) => {
  const { language = "jsx", scope = {}, imports = null, maxHeight = 'none', isModal } = props;
  const [code, setCode] = useState(props.code);
  const [copied, setCopied] = useState(false);
  const noInline = code.includes('render(');
  
  if(isModal) {
    return (
      <LiveProvider noInline={noInline} code={code} language={language} theme={themeStyle} scope={scope}>
              {/* <Card.Body style={{ maxHeight: maxHeight }}> */}
                <LivePreview />
              {/* </Card.Body> */}
      </LiveProvider>
    );
  } else {
    return (
      <LiveProvider noInline={noInline} code={code} language={language} theme={themeStyle} scope={scope}>
        <Row>
          <Col xs={12} className="mb-4">
            <Card>
              <Card.Body style={{ maxHeight: maxHeight }}>
                <LivePreview />
              </Card.Body>
            </Card>
          </Col>
          {imports ? (
            <Col xs={12} className="mb-4">
              <Code code={imports} />
            </Col>
          ) : null}
        </Row>
      </LiveProvider>
    );
  }
};

