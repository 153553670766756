import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { GeneralInfoForm } from "../components/Forms";
import useTitle from "../hooks/useTitle";

export default () => {
  useTitle("Settings")
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
