
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBullhorn, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "../../api/index"
import BgImage from "../../assets/img/illustrations/signin.svg";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import { Routes } from "../../routes";
import HashLoader from "react-spinners/HashLoader"
// import ReCAPTCHA from "react-google-recaptcha";

export default () => {
  useTitle("Sign in")
  const [ values, setValues ] = useState({ email: null, password: null })
  const [ errorValue, setErrorValue ] = useState(false)
  const [ successValue, setSuccessValue ] = useState(false)
  const [ isLoading, setLoading ] = useState(false)
  const { setAuth }  = useAuth();
  const [hiddenAlerts, setHiddenAlerts] = useState([]);
  // const recaptchaRef = useRef(null)
  const onClose = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setErrorValue(false)
    setHiddenAlerts(hiddenAlertsUpdated);
  };

  const loginAction = async () => {
    if(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(values.email) && values.password.length >= 6) {
      // const captchaToken = await recaptchaRef.current.executeAsync();
      // recaptchaRef.current.reset();
      try {
        setLoading(true)
        await axios.post("login-action", { ...values, 
          // recaptcha: captchaToken 
        }).then((res) => {
        if(res.status === 201) {
          onClose("success")
          onClose("danger")
          setSuccessValue("Login with success...")
          window.localStorage.setItem("isLogged", true)
          setTimeout(() => setAuth({ token: res?.data.token, username: res?.data.username, activePackage: res?.data.activePackage, maxCurrent: res?.data.maxCurrent, expireDate: res?.data.expireDate }) , 2000)
        } else {
          onClose("success")
          onClose("danger")
          setErrorValue(res.data?.error??res.data?.message)
        }
      })
      } catch(e) {
        onClose("success")
        onClose("danger")
        setErrorValue("An error occured.")
      } finally {
        setLoading(false)
      }
  } else {
    onClose("success")
    onClose("danger")
    setErrorValue("Please verify your entries.")
  }
}

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={"/"} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in</h3>
                </div>
                <Form className="mt-4">
                <React.Fragment>
                <Alert
              variant="success"
              show={successValue}
              onClose={() => onClose("success")}>

              <div className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                  <strong>{successValue}</strong>.
                </div>
                <Button variant="close" size="xs" onClick={() => onClose("success")} />
              </div>
              </Alert>
                <Alert
                  variant="danger"
                  show={errorValue}
                  onClose={() => onClose("danger")}>

                  <div className="d-flex justify-content-between">
                    <div>
                      <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                      <strong>{errorValue}</strong>.
                    </div>
                    <Button variant="close" size="xs" onClick={() => onClose("danger")} />
                  </div>
                </Alert>
                </React.Fragment>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup onChange={(e) => setValues({ ...values, email: e.target.value })}>
                      <InputGroup.Text >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="admin@utsniper.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup  onChange={(e) => setValues({ ...values, password: e.target.value })}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button onClick={() => loginAction()} variant="primary" className="w-100 inline-flex items-center">
                    Sign in
                    {isLoading && (
                      <HashLoader size={18} color="white ml-2" />
                    )}
                  </Button>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    No account ?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Signup here `}
                    </Card.Link>
                  </span>
                </div>
                {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  size="invisible"
                /> */}
                </Form>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
