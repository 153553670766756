import React from "react";
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import AccordionComponent from "../../components/AccordionComponent";
import useTitle from "../../hooks/useTitle";
const FAQ = [
    {
    id: 1,
    eventKey: "faq-accordion-1",
    title: "Can I get banned ?",
    description: `Yes, just like any FIFA bot, you still have a risk of being banned, because by using this kind of product you breaking EA's TOS.
    The most common sanction is a ban from using transfer market on the FUT Web App and Companion App for the current FIFA title.
    But on extremely rare occasions, it could be as severe as getting permanently banned from the game, this being said, none of our users reported having kind of severe sanction.`
    },
    {
        id:2,
        eventKey: "faq-accordion-2",
        title: "How many coins can I make per day ?",
        description: `This would usually depend on your experience with the Sniping Bot. Users who use the Sniping Bot regularly make an average of about 100,000 to 500,000 coins per day!`
    },
    {
        id:3,
        eventKey: "faq-accordion-3",
        title: "How can I be safe ?",
        description: `If you would like to keep your main account safe, we suggest using a secondary account, then transferring the coins to your main account using a safe coin distribution method. You need to also ensure that you play the game regularly to avoid EA suspicions, and always solve captchas correctly.
        Aswell if you getting a "Too Many Requests" error type, we suggest you to wait few hours/days before start using again a sniping bot, to prevent at 100% any ban.`
    },
    {
        id:4,
        eventKey: "faq-accordion-4",
        title: "Do you need my webapp credentials ?",
        description: `No, we have nothing to deal with your credentials and we will never ask for it.`
    },
    {
        id:5,
        eventKey: "faq-accordion-5",
        title: "In what devices is it working ?",
        description: `For the moment, UTSniper works only on Chrome extension in computer, Windows, Mac, Linux... , you just need to install our chrome extension to start using our product.`
    },
    {
        id:6,
        eventKey: "faq-accordion-6",
        title: "With what platform is it working ?",
        description: `You can use UTSniper with any platform, whether that's Xbox, PlayStation or PC. However, the Sniping Bot itself will need to run on your computer through the official FUT Web App.`
    },
    {
        id:7,
        eventKey: "faq-accordion-7",
        title: "How can I use the autobuyer ?",
        description: (
            <>
              If you don't have any experience with autobuyer, we will be pleased to help you achieve your goal. Contact us by joining our:
              <a href={process.env.REACT_APP_DISCORD_URL} target="_blank"> Discord </a>
              or by following the
              <a href={process.env.REACT_APP_YOUTUBE_TUTO_URL} target="_blank" > YouTube </a>
               tutorial teaching you how to start using it.
            </>
          ),
    },
    {
        id:8,
        eventKey: "faq-accordion-8",
        title: "I know how to use the autobuyer, but can't snipe properly.",
        description: (
            <>
              If you struggle to find good filters join our :
              <a href={process.env.REACT_APP_DISCORD_URL} target="_blank"> Discord </a>
              we release some free filters and we have aswell a premium package with private filters, all filters are daily reviewed.
            </>
          ),
    },
    {
      id:9,
      eventKey: "faq-accordion-9",
      title: "Where can I get the extension ?",
      description: (
          <>
            If you wan't to download our tool follow this to download the
            <a href={process.env.REACT_APP_EXTENSION_URL} target="_blank"> Chrome Extension </a>.
          </>
        ),
    }
]
export default () => {
useTitle("FAQS")
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      </div>

      <Row>
        <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
        <AccordionComponent 
        style={{ marginTop: "2%" }}
        defaultKey="faq-accordion-9"
        data={FAQ}
        />
        <div style={{ border: "1px rgba(15,15,15, .2) solid", padding: ".6em", borderRadius: "5px", marginTop: "1%", backgroundColor: "#e9eaec" }}><p style={{ fontWeight: "bold" }}>If you don't find the answers about any questions you have, join our <a href={process.env.REACT_APP_DISCORD_URL} target="_blank"> Discord</a>.</p></div>
        </Card.Body>
        </Card>
        </Col>
      </Row>
    </>
  );
};
