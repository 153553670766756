import axios from "axios"

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
    withCredentials: true,
    credentials: 'include'
},
);

export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, 
    headers: { 'Content-Type': 'application/json' }, 
    withCredentials: true,
    credentials: 'include'
});
// const axiosApi = axios.create({
//     baseURL: process.env.REACT_APP_BASE_URL,
//     withCredentials: true
// })

// const refreshToken = async () => {
//     const response = await axiosApi.get("rf-tk")
//     return response.data
// }

// export const signUp = async (username, email, password, passwordconfirm) => {
//     const response = await axiosApi.put("signup-action", { username, email, password, passwordconfirm })
//     return response.data
// }

// export const loginUser = async (email, password) => {
//     const response = await axiosApi.post("login-action", { email, password })
//     return response.data
// }

// export const logoutUser = async () => {
//     const response = await axiosApi.delete("logout-action")
//     return response.data
// }

// export const getTransactions = async () => {
//     await axiosApi.get("get-transactions").then((res) => {
//         if(res.status === 201) {
//             return res.data
//         } else {
            
//         }
//     })
    
// }

// export const updateSettings = async (infos) => {
//     return await axiosApi.patch(`/todos/`, infos)
// }

// export const deleteTodo = async ({ id }) => {
//     return await axiosApi.delete(`/todos/${id}`, id)
// }