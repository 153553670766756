import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

import useRefreshToken from '../hooks/useRefresh';

// pages
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import Feedback from "./feedback/feedback";
import Settings from "./Settings";
import BootstrapTables from "./tables/BootstrapTables";
import Home from "./examples/Home";
import Signin from "./examples/Signin";
import Signup from "./examples/Signup";
import ForgotPassword from "./examples/ForgotPassword";
import ResetPassword from "./examples/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages
import DocsOverview from "./documentation/DocsOverview";
import DocsDownload from "./documentation/DocsDownload";
import DocsQuickStart from "./documentation/DocsQuickStart";
import DocsLicense from "./documentation/DocsLicense";
import DocsFolderStructure from "./documentation/DocsFolderStructure";
import DocsBuild from "./documentation/DocsBuild";
import DocsChangelog from "./documentation/DocsChangelog";

// components
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import Packages from './examples/Packages';
import FAQ from './examples/FAQ';
import useAuth from '../hooks/useAuth';
import terms from './terms/terms';
import ConfirmAccount from './examples/ConfirmAccount';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => ( 
          <> 
          <Preloader show={loaded ? false : true} /> 
          <Component {...props} /> 
          </> 
          ) 
      }
    />
  )
};

const RouteWithLoaderProtected = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  useEffect(() => {
      let isMounted = true;
      let isTrying = false
      if(isMounted && !isTrying) {
        const verifyRefreshToken = async () => {
          isTrying = true
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
        }
        
        !auth?.token && window.localStorage.getItem("isLogged") === "true" && verifyRefreshToken();
      }

      return () => isMounted = false;
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        !Object.keys(auth).length ? ( 
          <> 
          <Preloader show={loaded ? false : true} /> 
          <Component {...props} /> 
          </> 
          ) : (
          <Redirect to={Routes.DashboardOverview.path} />
        )
      }
    />
  )
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { auth } = useAuth();
  // const refresh = useRefreshToken();

  // useEffect(() => {
  //     let isMounted = true;
  //     let isTrying = false
  //     if(isMounted && !isTrying) {
  //       const verifyRefreshToken = async () => {
  //         isTrying = true
  //           try {
  //               await refresh();
  //           }
  //           catch (err) {
  //               console.error(err);
  //           }
  //       }
  
  //       !auth?.token && window.localStorage.getItem("isLogged") === "true" && verifyRefreshToken();
  //     }

  //     return () => isMounted = false;
  // }, [])

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        Object.keys(auth).length ? (
          <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />
          <main className="content">
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
        ) : (
          <Redirect to={Routes.Signin.path} />
        )
      }
    />
  )
};

export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.Presentation.path} component={Home} />
    <RouteWithLoader exact path={Routes.ConfirmAccount.path} component={ConfirmAccount} />
    <RouteWithLoader exact path={Routes.Terms.path} component={terms} />
    <RouteWithLoader exact path={Routes.Feedback.path} component={Feedback} />
    <RouteWithLoaderProtected exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoaderProtected exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoaderProtected exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoaderProtected exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Captcha.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Packages.path} component={Packages} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.FAQ.path} component={FAQ} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
