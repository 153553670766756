import React from 'react';
import { Card, Accordion } from '@themesberg/react-bootstrap';

export default (props) => {
  // console.log(props)
  const { defaultKey, data = [], className = "", isPackage, setMethodPayment } = props;

  const AccordionItem = (item) => {
    const { eventKey, title, description, isPackage, isPaypal } = item;

    return (
      <Accordion.Item eventKey={eventKey} >
        <Accordion.Button variant="link" className="w-100 d-flex justify-content-between">
          <span className="h6 mb-0 fw-bold">
            {title}
          </span>
        </Accordion.Button>
        <Accordion.Body>
          <Card.Body className="py-2 px-0">
            <Card.Text className={`mb-0 ${isPackage && "d-flex justify-content-center align-items-center"} ${isPaypal && "flex-column-reverse"}`}>
              {description}
            </Card.Text>
          </Card.Body>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  if(isPackage) {
    return (
      <Accordion className={className} defaultActiveKey={defaultKey} onSelect={(e) => setMethodPayment(e)}>
        {data.map(d => <AccordionItem key={`accordion-${d.id}`} {...d} />)}
      </Accordion>
    );
  } else {
    return (
      <Accordion className={className} defaultActiveKey={defaultKey}>
        {data.map(d => <AccordionItem key={`accordion-${d.id}`} {...d} />)}
      </Accordion>
    );
  }
};