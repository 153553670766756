
import React from 'react';
import { Row, Col } from '@themesberg/react-bootstrap';

import CodeEditor from "./CodeEditor";

export default (props) => {
  const { title, description, example = null, imports = null, scope = {}, maxHeight = null, isModal } = props;
  return (
    <>
          {isModal ?
                  ""
      :
      <div className="pt-2">
      <Row>
        <Col xs={12}>
          <h2 className="fs-5">{title}</h2>
          {description}
        </Col>
      </Row>
    </div>
      }

      {isModal ?
        <CodeEditor isModal={isModal} code={example} scope={scope} imports={imports} maxHeight={maxHeight} />
      :
      <div className="pb-5">
      <Row className="mt-4">
        <Col xs={12}>
          <CodeEditor isModal={isModal} code={example} scope={scope} imports={imports} maxHeight={maxHeight} />
        </Col>
      </Row>
    </div>
      }
    </>
  );
};
