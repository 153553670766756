import axios from '../api/index';
import useAuth from './useAuth';
const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const refresh = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}rf-tk`).then((response) => {
            setAuth(prev => {
                return {
                    ...prev,
                    token: response?.data.token, 
                    username: response?.data.username, 
                    activePackage: response?.data.activePackage, 
                    maxCurrent: response?.data.maxCurrent, 
                    expireDate: response?.data.expireDate
                }
            }
            )
            return response.data.token;
        }).catch((err) => window.localStorage.setItem("isLogged", false))
        return response
    }
    return refresh;
};

export default useRefreshToken;