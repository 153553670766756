
import React from 'react';
import { RotateLoader } from "react-spinners"

export default (props) => {

  const { show } = props;

  return (
    <div className={`preloader bg-black flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <RotateLoader className="loader-element" color="orange" />
    </div>
  );
};
