export const pricingDataMonthly = [ 
    {   id: 1, 
        name: "Bronze Pack",
        price: 2.99, 
        advantages : ["1 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    }, 
    { 
        id: 2, 
        name: "Silver Pack",
        price: 4.99, 
        advantages : ["2 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    },
    { 
        id: 3, 
        name: "Gold Pack",
        price: 8.99, 
        advantages : ["4 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    },
    {   id: 7, 
        name: "Bronze Premium Pack",
        price: 7.99, 
        advantages : ["1 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"] 
    }, 
    { 
        id: 8, 
        name: "Silver Premium Pack",
        price: 9.99, 
        advantages : ["2 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"] 
    },
    { 
        id: 9, 
        name: "Gold Premium  Pack",
        price: 13.99, 
        advantages : ["4 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"] 
    }
]

export const pricingDataAnnualy = [ 
    {   id: 4, 
        name: "Jade Pack",
        price: 13.99, 
        advantages : ["1 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    }, 
    { 
        id: 5, 
        name: "Titan Pack",
        price: 18.99, 
        advantages : ["2 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    },
    { 
        id: 6, 
        name: "Ruby Pack",
        price: 28.99, 
        advantages : ["4 Concurrent", "Auto-buyer access", "FutBin infos about players"] 
    },
    {   id: 10, 
        name: "Jade Premium Pack",
        price: 24.99, 
        advantages : ["1 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"] 
    }, 
    { 
        id: 11, 
        name: "Titan Premium Pack",
        price: 39.99, 
        advantages : ["2 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"]  
    },
    { 
        id: 12, 
        name: "Ruby Premium Pack",
        price: 49.99, 
        advantages : ["4 Concurrent", "Auto-buyer access", "FutBin infos about players", "Home Made Private Filters", "Trading advices/alert"]  
    }
]