
import React from 'react';
import { Modal, Button } from '@themesberg/react-bootstrap';
import AccordionComponent from '../../components/AccordionComponent';
import valid from "../images/valid.png"
// const explorer = [ { url: "https://etherscan.io/tx/", network: "eth" }, { url: "https://bscscan.com/tx/", network: "BTC" }, { url: "https://live.blockcypher.com/ltc/tx/", network: "LTC" }, { url: "https://google.com", network: "TCN" }  ]
export default ({showDetails, setShowDetails, isModal}) => {
  const handleClose = () => setShowDetails({});
  return (
    <Modal as={Modal.Dialog} centered show={!!Object.keys(showDetails).length} onHide={handleClose}>
    <Modal.Header>
    <span>Transaction ID : {showDetails.transaction_id}</span>
    <Button variant="close" aria-label="Close" onClick={handleClose} />
    </Modal.Header>
    <Modal.Body>
    <div className="d-flex flex-column align-items-center">
    <span style={{ fontSize: "2em" }}>€{showDetails.price}</span>
    <span style={{ fontSize: "2em" }}>{showDetails.name}</span>
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop: "2%" }}>
    {showDetails.advantages.map((v, i) => {
          return (<span style={{ fontSize: "1.2em", marginTop: "2%" }} key={i}> <img width={24} height={24} src={valid} /> {v}</span>)
    })}
    </div>
    {showDetails.network !== "Paypal" && typeof showDetails.payment_history === "object" && showDetails.payment_history?.length > 0 && 
    <AccordionComponent 
    style={{ marginTop: "2%" }}
    defaultKey="transaction-accordion"
    data={[{
      id: 1,
      eventKey: "transaction-accordion",
      title: "Transactions History",
      description: showDetails.payment_history.map((el) => {
      return <div className='d-flex flex-row justify-content-center align-items-center' style={{ marginTop: "1%", marginBottom: "1%" }}><a href={el.explorer_url} target="_blank">{el.txid.length > 10 ? el.txid.slice(0, 10) + "..." : el.txid}</a> Confirmations : {el.confirmation}</div>
    })}]}
    />
    }
    {showDetails.status === "Pending" && showDetails.network !== "Paypal" && (<Button onClick={() => window.open(showDetails.payment_link, "_blank") } className="mt-2" variant="primary">Resume Payment</Button>)}
    </div>
    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={handleClose}>
     Cancel
    </Button>
    </Modal.Footer>
    </Modal>
  );
};
