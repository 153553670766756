
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCalendarDay, faLaptopCode, faPalette } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Nav, Tab, Card } from '@themesberg/react-bootstrap';
import Pricing from "./Pricing";
import { pricingDataMonthly, pricingDataAnnualy } from "../../data/pricing";
import Modals from "./Modals";
export default () => {
  const [ShowBuy, setShowBuy] = useState({})

  return (
        <Row className="mt-5">
          <Col xs={12} className="mb-4">
            <Card>
              <Card.Body>
    {ShowBuy?.id ? <Modals setShowBuy={setShowBuy} ShowBuy={ShowBuy} isModal={true} /> : null}
        <Tab.Container defaultActiveKey="period">
    <Row>
    <Col lg={12}>
      <Nav fill variant="pills" className="flex-column flex-sm-row">
        <Nav.Item>
          <Nav.Link eventKey="period" className="mb-sm-3 mb-md-0 bg-primary text-white">
            <FontAwesomeIcon icon={faCalendarDay} className="me-2" /> Monthly
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="code_friendly" className="mb-sm-3 mb-md-0 bg-primary text-white">
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2" /> Annualy
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="period" className="py-4 flex flex-column">
        <Pricing data={pricingDataMonthly} period="Monthly" setShowBuy={setShowBuy} />
        </Tab.Pane>
        <Tab.Pane eventKey="code_friendly" className="py-4">
        <Pricing data={pricingDataAnnualy} period="Annualy" setShowBuy={setShowBuy}  />
        </Tab.Pane>
      </Tab.Content>
    </Col>
    </Row>
    </Tab.Container>
    </Card.Body>
    </Card>
    </Col>
    </Row>
  );
};