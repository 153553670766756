
import React, { useState } from "react";
import { faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';

import { CounterWidget } from "../../components/Widgets";
import { TransactionsTable } from "../../components/Tables";
import ModalTransaction from "../components/ModalTransaction";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";

export default () => {
  const [ showDetails, setShowDetails ] = useState({})
  const { auth } = useAuth()
  useTitle("Dashboard")
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            {showDetails?.name &&
        <ModalTransaction showDetails={showDetails} setShowDetails={setShowDetails} />}
        {/* <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        {/* <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup> */}
      </div>

      <Row className="justify-content-md-center">
        {/* <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}
        {/* <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Sales Value"
            value="10,567"
            percentage={10.57}
          />
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="mb-4 h-[100%]">
          <CounterWidget
            name="Gazz"
            maxCurrent={auth?.maxCurrent}
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="mb-4">
          <CounterWidget
            category="Actual membership"
            title={auth?.activePackage??"None"}
            period={auth?.expireDate ? new Date(auth?.expireDate).toLocaleDateString() + " at " + new Date(auth?.expireDate).toLocaleTimeString() : null}
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            {/* <Col xs={12} xl={12} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <TeamMembersWidget />
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <ProgressTrackWidget />
                </Col>
              </Row>
            </Col> */}

            {/* <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Total orders"
                    value={452}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  <RankingWidget />
                </Col>

                <Col xs={12} className="px-0">
                  <AcquisitionWidget />
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <TransactionsTable showDetails={showDetails} setShowDetails={setShowDetails}  />
    </>
  );
};
