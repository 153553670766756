
import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBullhorn, faEnvelope, faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "../../api/index"
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import useTitle from "../../hooks/useTitle";
import ReCAPTCHA from "react-google-recaptcha";
import HashLoader from "react-spinners/HashLoader"

export default () => {
  useTitle("Sign up")
  const [ values, setValues ] = useState({ username: "", email: "", password: "", passwordconfirm: "" })
  const [ errorValue, setErrorValue ] = useState(false)
  const [ successValue, setSuccessValue ] = useState(false)
  const [ isLoading, setLoading ] = useState(false)
  const [hiddenAlerts, setHiddenAlerts] = useState([]);
  const recaptchaRef = useRef(null)
  const onClose = (alertId) => {
    const hiddenAlertsUpdated = [...hiddenAlerts, alertId];
    setErrorValue(false)
    setHiddenAlerts(hiddenAlertsUpdated);
  };
  const createAccount = async () => {
    if(values.username.length <= 4 && !/^[a-zA-Z0-9]+$/.test(values.username)) {
      return setErrorValue("Your username can contain only caracteres alphabetic and numbers, and minimum length of 4.")
    }
    if(!/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(values.email)) {
      return setErrorValue("Verify the email you specified.")
    } 
    if((values.password.length < 6 && values.password.length < 6) || values.password.length && values.password !== values.passwordconfirm) {
      return setErrorValue("Verify the password and the confirm password you specified, and the length need to be at least of 6.")
    }
    if(Object.keys(values).every((e) => ["username", "email", "password", "passwordconfirm"].includes(e))) {
      try {
        setLoading(true)
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        await axios.put("signup-action", { ...values, recaptcha: captchaToken }).then((res) => {
          if(res.status === 201) {
            setSuccessValue("Account created with success, we sent you an email to verify your account...")
            setTimeout(() => window.location.href = "/#/sign-in", 8000)
          } else {
            setErrorValue(res.data?.error??res.data?.message)
          }
        })
      } catch (e) {
        setErrorValue("An error occured")
      } finally {
        setLoading(false)
      }
    }
  }
  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={"/"} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4">
                <React.Fragment>
                <Alert
                  variant="danger"
                  show={errorValue}
                  onClose={() => onClose("danger")}>

                  <div className="d-flex justify-content-between">
                    <div>
                      <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                      <strong>{errorValue}</strong>.
                    </div>
                    <Button variant="close" size="xs" onClick={() => onClose("danger")} />
                  </div>
                </Alert>
              <Alert
              variant="success"
              show={successValue}
              onClose={() => onClose("success")}>

              <div className="d-flex justify-content-between">
                <div>
                  <FontAwesomeIcon icon={faBullhorn} className="me-1" />
                  <strong>{successValue}</strong>
                </div>
                <Button variant="close" size="xs" onClick={() => onClose("success")} />
              </div>
              </Alert>
                </React.Fragment>
                <Form.Group id="username" className="mb-4">
                    <Form.Label>Your Username</Form.Label>
                    <InputGroup onChange={(e) => setValues({ ...values, username: e.target.value })}>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="username" placeholder="utsniperbot1" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup onChange={(e) => setValues({ ...values, email: e.target.value })}>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="admin@utsniper.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup onChange={(e) => setValues({ ...values, password: e.target.value })}>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="**********" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup onChange={(e) => setValues({ ...values, passwordconfirm: e.target.value })}>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="**********" />
                    </InputGroup>
                  </Form.Group>
                  {/* <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck> */}

                  <Button variant="primary" onClick={() => createAccount()} className="w-100 inline-flex items-center">
                    Sign up 
                    {isLoading && (
                      <HashLoader size={18} color="white ml-2" />
                    )}
                  </Button>
                  <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  size="invisible"
                />
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
